<template>
  <div class="bigbox" style="margin-top: 30px; margin-left: 60px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item :label="$t('AppManage.WatermarkAnalysis.SelectPicture')+'：'" prop="appName">
        <el-input :disabled="true" size="small" v-model="ruleForm.appName" :placeholder="$t('AppManage.WatermarkAnalysis.placeholderPic')" style="width: 215px"></el-input>
        <el-upload action="#" :auto-upload="false" :show-file-list="false" :on-success="handleAvatarSuccess1" class="el-button mar-class-8" style="padding: 0; margin-left: 12px; border: none" :on-change="changeUpload" multiple accept=".png,.jpeg,.jpg">
          <el-button size="small" style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{$t('AppManage.WatermarkAnalysis.SelectPicture')}}</span>
          </el-button>
        </el-upload>
        <span style="margin-left:10px;color:#C0C4CC;">{{$t('AppManage.WatermarkAnalysis.postMessage')}}</span>
        <el-progress v-if="flagStatus" :percentage="percentage" :color="customColorMethod" style="width: 230px"></el-progress>
      </el-form-item>
      <el-form-item>
        <el-button size="small" :loading="logdingFlag" @click="submitForm('ruleForm')" type="primary">{{$t('AppManage.WatermarkAnalysis.analysisBtn')}}</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { dialogInfo },
  props: {},
  created() {
  },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t('AppManage.WatermarkAnalysis.Error3')))
      } else {
        callback()
      }
    }
    return {
      logdingFlag: false,
      flagStatus: false,
      percentage: 0,
      titleInfo: '保存成功',
      visibaelFlag: false,
      checked: true, // 安装类型的多选框
      queryObj: {
        manage: 1,
        selectcont: '' //下拉矿的值
      },
      options: [],
      ruleForm: {
        appName: '', //
        region: '', //
        selectcont: '', //下拉矿的值
        appcategoryid: '',
        osType: '20',
        department: '',
        AppId:'',
        version:'',
        urls:""
      },
      path: '',
      rules: {
        appName: [
          { required: true, message: this.$t('AppManage.WatermarkAnalysis.placeholderPic'), trigger: 'blur' },
          // { validator: validateInput, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    checkSpecVersion(str) {
        var specialKey =/^([a-zA-Z0-9]){1}(\w|\.|\s*)+$/
        if (specialKey.test(str)) {
          return false
        }
      return true
    },
    mathdom() {
      var num = Math.floor(Math.random() * 10 + 1)
      return num
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 70) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    },
    async saveSubmit() {
      this.$message({
            message: this.$t('AppManage.WatermarkAnalysis.CheckDel3'),
            type: 'success',
            offset: 100
      })
      this.logdingFlag = true
        let params = {
          path: this.path
        }
        const res = await this.$axios.post(
          '/httpServe/darkWater/save',
          params,
          true
        )
        if(res.data == 0) {
          this.logdingFlag = false
          this.$message({
            message: this.$t('AppManage.WatermarkAnalysis.CheckDel4'),
            type: 'error',
            offset: 100
          })
        }
        if (res.data == 1) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm = {}
          this.logdingFlag = false
          this.ruleForm.appType = false
          this.$emit('haldesotr')
        }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    handleClose() {
      this.visibaelFlag = false
    },
    //文件上传接口
    async changeUpload(file) {
      this.percentage = 0
      this.flagStatus = true
      let suffix = this.getFileType(file.name) //获取文件后缀名
      let suffixArray = ['png','jpg','jpeg'] //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('AppManage.WatermarkAnalysis.Error5'),
          type: 'error',
          offset: 100
        })
        this.flagStatus = false
      } else if (file.size / (1024 * 1024) > 20) { //限制文件大小
        this.$message.warning(this.$t('AppManage.WatermarkAnalysis.Error4'));
        this.flagStatus = false
        return false;
      } else {
        const form = new FormData()
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "img");
        form.append("moduleName", "protected");
        const res = await this.$axios.upload(
          '/httpServe/sysFile/fileUp',
          form,
          this.updateProgress,
          true
        )
        this.path = res.data.data[0]
        if (this.path) {
          this.$message({
            message: this.$t('AppManage.WatermarkAnalysis.CheckDel5'),
            type: 'success',
            offset: 100
          })
          this.flagStatus = false
        } else {
          this.$message({
            message: this.$t('AppManage.WatermarkAnalysis.Error6'),
            type: 'error',
            offset: 100
          })
          this.flagStatus = false
        }
        this.$nextTick(() => {
          this.$set(this.ruleForm, 'appName', file.name)
        })
        if (res.code == 200) {
          this.$emit('haldesotr')
        }
      }
    },
    updateProgress(e) {//e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100)
    },
    handleAvatarSuccess1() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
