<template>
  <div>
    <div class="box" v-if="flag == 1">
      <el-row :gutter="5" class="mt15" style="display: flex">
        <el-col :span="3">
          <el-input v-model.trim="name" :placeholder="$t('AppManage.WatermarkAnalysis.placeholder')" size="small" @keyup.enter.native="search"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" class="searchbtn" size="small" @click="search">{{$t('public.Inquire')}}</el-button>
        </el-col>
      </el-row>
      <el-row style="padding: 10px">
        <el-col :span="24">
          <el-button type="primary" @click="addNew" class="addbtn" size="small">{{$t('AppManage.WatermarkAnalysis.UploadPictures')}}</el-button>
          <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="delArr(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">{{$t('public.Delete')}}</el-button>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <div style="position: relative; margin-right: 10px">
        <el-table ref="tableData" :data="tableData" :row-key="getRowKeys" lazy size="mini" stripe fit :row-style="iRowStyle"
          :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }" class="tableClass" tooltip-effect="dark" style="width: 100%; margin-left: 10px;padding-right: 10px"
          :default-sort="{ prop: 'createTime', order: 'descending' }" @selection-change="delobj" @sort-change="sort">
          <el-table-column type="selection" align="center" width="60" :reserve-selection="true"> </el-table-column>
          <el-table-column prop="name" :label="$t('AppManage.WatermarkAnalysis.Name')" sortable="custom" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="sizeStr" :label="$t('public.Size')" width="260" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="groupFullName" :label="$t('public.Section')" width="180" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" :label="$t('AppManage.WatermarkAnalysis.CreationTime')" width="180" sortable="custom" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.createTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <img @click="ViewPictures(scope.row)" src="@/images/image_uni.png"
                style="width: 15px; height: 15px; cursor: pointer" :title="$t('AppManage.WatermarkAnalysis.ViewPicture')" />
              <img @click="analysisImg(scope.row.id)" src="@/assets/icon_del.png" style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                " :title="$t('public.Delete')" />
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination style="margin-left: 10px" :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"></pagination>
      </div>
    </div>
    <!-- 新增 -->
    <uploadImg v-if="flag == 2" @addSave="saveFlag" @haldesotr="haldesotr" :editID="editData" />
    <div @click="closeImgae" class="dialog_img" v-if="dialog_img">
      <div class="dialog_img_main" @click.stop>
        <div class="dialog_img_head">
          <span v-if="!LastPictureFlag" style="margin-left:42px;"> </span>
          <i v-if="LastPictureFlag" class="el-icon-arrow-left" :title="$t('AppManage.WatermarkAnalysis.Previous')" @click="LastPicture"></i>
          <span style="margin:0 15px;" v-if="imageIndex!==0"></span>
          <span class="el-icon-span">{{imageIndex+1}}<span v-if="imageIndex==0">({{$t('AppManage.WatermarkAnalysis.OriginalDrawing')}})</span>/{{filePath.length}}</span>
          <span style="margin:0 15px;" v-if="imageIndex!==0"></span>
          <span v-if="!NextPictureFlag" style="margin-left:20px;"> </span>
          <i v-if="NextPictureFlag" class="el-icon-arrow-right" :title="$t('AppManage.WatermarkAnalysis.NextSheet')" @click="NextPicture"></i>
          <i class="el-icon-close" @click="closeImgae" :title="$t('AppManage.WatermarkAnalysis.Close')"></i>
        </div>
        <div class="dialog_img_content"
          @mouseenter="bannerMouseenter()"
          @mouseleave="bannerMouseleave()"
        >
          <div
              @click="downloadpic()"
              v-show="isbanner"
              style="
              cursor: pointer;
              width: 400px;
              height: 60px;
              background: rgba(0,0,0,0.3);
              "
              class="mask-title"
              >
              <img
              src="@/images/ico_picdownload.png"
              :title="$t('public.Download')"
              alt=""
              style="
              width: 40px;
              height: 40px;
              position: relative;
              clear: both;
              display: block;
              margin: auto;
              margin-top: 10px;"/>
            </div>
          <img :src="imageSrc" alt="">
        </div>
      </div>
    </div>
    <!-- 保存成功的弹框 -->
    <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="seccess"
      :width="'240px'" />
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog'
import uploadImg from './components/uploadImg.vue'
const Base64 = require("js-base64").Base64;
export default {
  components: {
    pagination,
    dialogInfo,
    Eldialog,
    uploadImg
  },
  data() {
    return {
      typeInfo: 'info',// 删除按钮默认灰色
      groupFullNameShowByIndex: '',
      name: '',
      flag: 1,
      imageSrc:'',
      imageList:[],
      delarr: [],
      tableData: [],
      dialog_img:false,
      isbanner:true,
      LastPictureFlag:false,
      NextPictureFlag:true,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      Successdialog: false,
      seccess: '',
      editID: '',
      editData: {},
      sorts: 'w.updateTime desc',
      orderColume: 'createTime',
      orderRule: 'DESC',
      imageIndex:0,
      filePath:[],
      imgUrl: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1)
    }
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.getDataList()
  },
  methods: {
    bannerMouseenter() {
      this.isbanner = true;
    },
    bannerMouseleave() {
      this.isbanner = false;
    },
    downloadpic() {
      this.downloadIamge(this.imageSrc, "pic");
    },
    getRowKeys(row) {
      return row.id
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    },
    // 查询
    async search() {
      if (this.name.length > 0) {
        this.currentPage = 1
      }
      this.getDataList()
    },
    // 列表
    async getDataList() {
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        name: this.name
      }
      const res = await this.$axios.post(
        '/httpServe/darkWater/getDataInfo',
        parma,
        true
      )
      console.log(res, '水印列表')
      this.tableData = res.data.content
      this.total = res.data.total
    },
    // 排序
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.prop == 'createTime') {
        if (column.order == 'ascending') {
          this.orderRule = 'ASC'
        } else {
          this.orderRule = 'DESC'
        }
        this.orderColume = column.prop
      } else if (column.prop == 'name') {
        if (column.order == 'ascending') {
          this.orderRule = 'ASC'
        } else {
          this.orderRule = 'DESC'
        }
        this.orderColume = column.prop
      } else if (column.prop == 'groupFullName') {
        if (column.order == 'ascending') {
          this.orderRule = 'ASC'
        } else {
          this.orderRule = 'DESC'
        }
        this.orderColume = column.prop
      }
      this.getDataList()
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page
      this.pageSize = limit
      // 调用查询方法
      this.getDataList()
    },
    // 选中数组 批量删除
    delobj(obj) {
      this.delarr = obj
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    delArr(obj) {
      var ids = []
      obj.forEach((item) => {
        ids.push(item.id)
      })
      var param = { ids: ids }
      console.log(param)
      this.$confirm(this.$t('AppManage.WatermarkAnalysis.CheckDel'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          // 请求接口
          const res = await this.$axios.post(
            '/httpServe/darkWater/delete',
            param,
            true
          )
          if (res.data == -1) {
            this.$message({
              message: this.$t('AppManage.WatermarkAnalysis.Error1'),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            });
            this.$refs.tableData.clearSelection();
            this.getDataList()
          }
        })
        .catch(() => {
          return false
        })
    },
    // 删除
    analysisImg(id) {
      this.$confirm(this.$t('AppManage.WatermarkAnalysis.CheckDel1'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          // 请求接口
          var parma = { ids: [id] }
          console.log(parma)
          const res = await this.$axios.post(
            '/httpServe/darkWater/delete',
            parma,
            true
          )
          if (res.data == -1) {
            this.$message({
              message: this.$t('AppManage.WatermarkAnalysis.Error1'),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            });
            this.$refs.tableData.clearSelection();
            this.getDataList()
          }
        })
        .catch(() => {
          return false
        })
    },
    // 新增
    addNew() {
      this.editData.id = ''
      this.editData = {}
      this.flag = 2
    },
    // 新增弹框
    saveFlag(val) {
      if (val.errorCode == '12295') {
        this.$message({
          message: this.$t('AppManage.WatermarkAnalysis.Error2'),
          type: "error",
          offset: 100,
        });
      } else if (val.errorCode == '65450') {
        this.$message({
          message: this.$t('AppManage.WatermarkAnalysis.Error2'),
          type: "error",
          offset: 100,
        });
      } else if (val.data == -1) {
        this.$message({
          message: this.$t('AppManage.WatermarkAnalysis.Error2'),
          type: "error",
          offset: 100,
        });
      } else {
        this.flag = 1
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        });
        this.getDataList()
      }
    },
    closeImgae() {
      this.$nextTick(()=>{
        this.imageList = []
        this.imageIndex = 0
        this.imageSrc = ""
      })
      this.dialog_img = false
    },
    // 回显
    ViewPictures(val) {
      this.filePath = val.filePath
      this.downline(this.filePath[this.imageIndex])
      this.imageIndex = 0
      if(this.imageIndex == 0) {
        this.LastPictureFlag = false
      }else {
        this.LastPictureFlag = true
      }
      if(this.imageIndex== this.filePath-1) {
        this.NextPictureFlag = false
      }else {
        this.NextPictureFlag = true
      }
      this.editData = val
      this.dialog_img = true
    },
    //上一张
    LastPicture() {
      this.imageIndex--
      this.downline(this.filePath[this.imageIndex])
      if(this.imageIndex == 0) {
        this.LastPictureFlag = false
      }else {
        this.LastPictureFlag = true
      }
      if(this.imageIndex== this.filePath.length-1) {
        this.NextPictureFlag = false
      }else {
        this.NextPictureFlag = true
      }
      console.log(this.imageIndex)
      this.imageSrc = this.imageList[this.imageIndex]
    },
    //下一张
    NextPicture() {
      this.imageIndex++
      this.downline(this.filePath[this.imageIndex])
      console.log(this.imageIndex)
      if(this.imageIndex == 0) {
        this.LastPictureFlag = false
      }else {
        this.LastPictureFlag = true
      }
      if(this.imageIndex== this.filePath.length-1) {
        this.NextPictureFlag = false
      }else {
        this.NextPictureFlag = true
      }
      this.imageSrc = this.imageList[this.imageIndex]
    },
    // 点击下载图片
    downloadIamge(imgurl, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        }); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgurl;
    },
    async downline(path, data, img) {
      var param = {
        type: "protected",
        path: path,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      let url = Base64.decode(res.data);
      url = this.imgUrl + url;
      this.imageList.push(url)
      this.imageSrc = url
      // console.log(this.imageList)
    },
    haldesotr(){
      this.$message({
          message: this.$t('AppManage.WatermarkAnalysis.CheckDel2'),
          type: 'success',
          offset: 100
      })
      this.flag = 1
      this.getDataList()
    },
    // 弹框
    handleClose() {
      this.Successdialog = false //提示消息  关闭
    },
    determine() {
      this.Successdialog = false //提示消息  关闭
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-button+.el-button {
  margin-left: 1px;
}

.mt15 {
  padding: 10px 0 0 10px;
}
.dialog_img {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0,0,0,0.5);
}
.dialog_img_main {
  z-index: 2;
  opacity: 1;
  width: 400px;
  height: 600px;
  background: #fff;
  margin: 100px auto;
}
.dialog_img_head {
  height: 40px;
  line-height: 45px;
  background: #fff;
}
.dialog_img_content {
  width: 400px;
  height: 700px;
  position: relative;
  img {
    width: 400px;
    height: 700px;
  }
  .mask-title {
    position: absolute;
    bottom: 0;
  }
}
.el-icon-arrow-left {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 20px;
}
.el-icon-arrow-right {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.el-icon-close {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.el-icon-span {
  font-size: 24px;
  margin: 0 85px;
}
::v-deep .el-table__row>td { border: none; }
::v-deep .el-table::before { height: 0px; }
::v-deep .el-table th.is-leaf {border: none;}
</style>